

@mixin animated ($time: .2s) {
    animation-duration: $time;
    animation-fill-mode: both;
}
@mixin setup-fadeIn {
    @keyframes fadeIn {
    	0% {opacity: 0;}
    	100% {opacity: 1;}
    }
}
@mixin fadeIn {
	animation-name: fadeIn;
}
@mixin setup-fadeInUp {
    @keyframes fadeInUp {
    	0% {
    		opacity: 0;
    		transform: translateY(20px);
    	}

    	100% {
    		opacity: 1;
    		transform: translateY(0);
    	}
    }
}
@mixin fadeInUp {
	animation-name: fadeInUp;
}
@mixin setup-fadeExpand {
    @keyframes fadeExpand {
        0% {
            opacity: 0;
            transform: scale(.9);
        }

        100% {
            opacity: 1;
            transform: scale(1);
        }
    }
}
@mixin fadeExpand {
    animation-name: fadeExpand;
}
@mixin setup-fadeCompress {
    @keyframes fadeCompress {
        100% {
            opacity: 0;
            transform: scale(0);
        }

        0% {
            opacity: 1;
            transform: scale(1);
        }
    }
}
@mixin fadeCompress {
    animation-name: fadeCompress;
}
@mixin setup-fadeInDown {
    @keyframes fadeInDown {
    	0% {
    		opacity: 0;
    		transform: translateY(-20px);
    	}

    	100% {
    		opacity: 1;
    		transform: translateY(0);
    	}
    }
}
@mixin fadeInDown {
	animation-name: fadeInDown;
}
@mixin setup-fadeInLeft {
    @keyframes fadeInLeft {
    	0% {
    		opacity: 0;
    		transform: translateX(-20px);
    	}

    	100% {
    		opacity: 1;
    		transform: translateX(0);
    	}
    }
}
@mixin fadeInLeft {
	animation-name: fadeInLeft;
}
@mixin setup-fadeInRight {
    @keyframes fadeInRight {
    	0% {
    		opacity: 0;
    		transform: translateX(20px);
    	}

    	100% {
    		opacity: 1;
    		transform: translateX(0);
    	}
    }
}
@mixin fadeInRight {
	animation-name: fadeInRight;
}
@mixin setup-fadeOut {
    @keyframes fadeOut {
    	0% {opacity: 1;}
    	100% {opacity: 0;}
    }
}
@mixin fadeOut {
	animation-name: fadeOut;
}
@mixin setup-fadeOutUp{
    @keyframes fadeOutUp {
    	0% {
    		opacity: 1;
    		transform: translateY(0);
    	}

    	100% {
    		opacity: 0;
    		transform: translateY(-20px);
    	}
    }
}
@mixin fadeOutUp {
	animation-name: fadeOutUp;
}
@mixin setup-fadeOutDown {
    @keyframes fadeOutDown {
    	0% {
    		opacity: 1;
    		transform: translateY(0);
    	}

    	100% {
    		opacity: 0;
    		transform: translateY(20px);
    	}
    }
}
@mixin fadeOutDown {
	animation-name: fadeOutDown;
}
@mixin setup-fadeOutLeft {
    @keyframes fadeOutLeft {
    	0% {
    		opacity: 1;
    		transform: translateX(0);
    	}

    	100% {
    		opacity: 0;
    		transform: translateX(-20px);
    	}
    }
}
@mixin fadeOutLeft {
	animation-name: fadeOutLeft;
}
@mixin setup-fadeOutRight {
    @keyframes fadeOutRight {
    	0% {
    		opacity: 1;
    		transform: translateX(0);
    	}

    	100% {
    		opacity: 0;
    		transform: translateX(20px);
    	}
    }
}
@mixin fadeOutRight {
	animation-name: fadeOutRight;
}
@mixin setup-puffIn {
    @keyframes puffIn {
      0% {
        opacity: 0;
        -webkit-transform-origin: 50% 50%;
        -moz-transform-origin: 50% 50%;
        -ms-transform-origin: 50% 50%;
        -o-transform-origin: 50% 50%;
        transform-origin: 50% 50%;
        -webkit-transform: scale(2, 2);
        -moz-transform: scale(2, 2);
        -ms-transform: scale(2, 2);
        -o-transform: scale(2, 2);
        transform: scale(2, 2);
      }

      100% {
        opacity: 1;
        -webkit-transform-origin: 50% 50%;
        -moz-transform-origin: 50% 50%;
        -ms-transform-origin: 50% 50%;
        -o-transform-origin: 50% 50%;
        transform-origin: 50% 50%;
        -webkit-transform: scale(1, 1);
        -moz-transform: scale(1, 1);
        -ms-transform: scale(1, 1);
        -o-transform: scale(1, 1);
        transform: scale(1, 1);
      }
    }
}
@mixin puffIn {
  animation-name: puffIn;
}
@mixin setup-puffOut {
    @keyframes puffOut {
      0% {
        opacity: 1;
        transform-origin: 50% 50%;
        transform: scale(1, 1);
      }

      100% {
        opacity: 0;
        transform-origin: 50% 50%;
        transform: scale(2, 2);
      }}
}
@mixin puffOut {
  animation-name: puffOut;
}
