// Variables for global use

//**** Colors
$purple: #2A2240;
$white: #fff;
$black: #000;
$color:#D6D717;

//Selection
::-moz-selection {
  background: $color;
  color: $white;
}
::selection {
  background: $color;
  color: $white;
}

//Shadows
$shadow: 0 0 40px 0 rgba(0,0,0,.20);
$shadow-lrg: 0 20px 60px 0 rgba(0,0,0,.50);
$shadow-lg: 0 20px 60px 0 rgba(0,0,0,.25);
$shadow-sm: 0 6px 20px 0 rgba(0,0,0,.1);
$shadow-hov: 0 10px 40px 0 rgba(0,0,0,.25);

//Gradient
$black-grad: linear-gradient(-180deg, rgba(0,0,0,.1) 30%, rgba(0,0,0,.4) 79%);
$black-grad-2: linear-gradient(-180deg, rgba(0,0,0,.4) 0%, rgba(0,0,0,.9) 100%);
$black-grad-3: linear-gradient(-180deg, rgba(0,0,0,.7) 30%, rgba(0,0,0,.8) 79%);

$black-grad-4: linear-gradient(-180deg, rgba(0,0,0,.66) 30%, rgba(0,0,0,.86) 79%);

$white-grad: linear-gradient(0deg, rgba(255,255,255,.5) 0%, rgba(255,255,255,1) 100%);

$white-grad-top: linear-gradient(0deg, rgba(255,255,255,0) 10%, rgba(255,255,255,1) 90%);
$white-grad-bottom: linear-gradient(180deg, rgba(255,255,255,0) 10%, rgba(255,255,255,1) 90%);

$blue-grad: linear-gradient(130deg, rgba(81,163,210,.5) 0%, rgba(40,106,166,1.0) 83%);


//**** Typography
$font-1: orpheuspro, sans-serif;
$font-2: plantin, sans-serif;


//**** Transitions
$transition: .3s all ease-in-out;
$dt: .3s all ease-in-out;

//**** Animations
@include setup-fadeInUp;
@include setup-fadeIn;
@include setup-fadeInLeft;
@include setup-fadeInRight;
@include setup-fadeInDown;

//Containers
.container {
  max-width: 1230px;
  width: 100%;
  margin: 0 auto;
  padding: 0 40px;
}
main {
  width: 100%;
}

//Button
.button {
  display: inline-block;
  padding: 6px 40px;
  background: $color;
  font-family: $font-1;
  font-style: italic;
  font-weight: 700;
  color: $white;
  font-size: 1.125em;
  border-radius: 30px;
  text-decoration: none;
  text-align: center;
  transition: $transition;
  &:hover {
    border-radius: 0px;
  }
}
//IMG FUll
.img-full {
  margin: 0 auto;
  max-width: 100%;
  padding: 0 20px;
  text-align: center;
}
