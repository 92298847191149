/* Slick-Slider */
.slick-slider {
    position: relative;
    display: block;
    box-sizing: border-box;
    -ms-touch-action: pan-y;
    touch-action: pan-y;
    -webkit-tap-highlight-color: transparent;
    outline: none;
    &.single {
      .slick-dots {
        display: none;
      }
    }
}
.slick-list {
    position: relative;
    overflow: hidden;
    display: block;
    margin: 0;
    padding: 0;
    outline: none;

    &:focus {
        outline: none;
    }

    &.dragging {
        cursor: pointer;
        cursor: hand;
    }
}
.slick-slider .slick-track,
.slick-slider .slick-list {
    transform: translate3d(0, 0, 0);
}

.slick-track {
    position: relative;
    left: 0;
    top: 0;
    display: block;

    &:before,
    &:after {
        content: "";
        display: table;
    }

    &:after {
        clear: both;
    }

    .slick-loading & {
        visibility: hidden;
    }
}
.slick-slide {
    float: left;
    height: 100%;
    min-height: 1px;
    outline: none;
    [dir="rtl"] & {
        float: right;
    }
    img {
        display: block;
    }
    &.slick-loading img {
        display: none;
    }

    display: none;

    &.dragging img {
        pointer-events: none;
    }

    .slick-initialized & {
        display: block;
    }

    .slick-loading & {
        visibility: hidden;
    }

    .slick-vertical & {
        display: block;
        height: auto;
        border: 1px solid transparent;
    }
}
.slick-arrow.slick-hidden {
    display: none;
}
.slick-dots {
	position: absolute;
	text-align: center;
	z-index: 10;
	li {
		display: inline-block;
		width: 8px;
		height: 8px;
		background: transparent;
		margin: 0 6px;
    border-radius: 50%;
    background: red;
		cursor: pointer;
    transition: $dt;
		position: relative;
    opacity: .5;
    &.slick-active,
    &:hover {
      opacity: 1;
    }
    &.slick-active {
      background: blue;
      transform: scale(1.5);
    }
    &:before {
      display: none;
    }
    &:after {
      display: none;
    }
	}
	button {
		appearance: none;
		background: none;
		width: 100%;
		height: 100%;
		outline: none;
		border: none;
    position: absolute;
    left: 0;
    top: 0;
		text-indent: -9999em;
		cursor: pointer;
	}
}
