.intro {
  width: 100%;
  height: 100%;
  .container {
    min-height: 100vh;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    @include breakpoint(640px) {
      align-items: flex-start;
    }
  }
  &__content {
    max-width: 580px;
    padding: 40px 0;
    text-align: center;
    h1 {
      font-family: $font-1;
      font-weight: 700;
      text-align: right;
      font-size: 6.5em;
      color: $color;
      line-height: .8;
      @include animated(.475s);
      @include fadeInRight;
      animation-delay: .2s;
      @include breakpoint(640px) {
        text-align: center;
      }
    }
    h2 {
      font-family: $font-1;
      font-weight: 700;
      text-align: right;
      font-size: 3em;
      line-height: 1;
      margin-bottom: 30px;
      @include animated(.475s);
      @include fadeInLeft;
      animation-delay: .3s;
      @include breakpoint(640px) {
        text-align: center;
      }
    }
    p {
      text-align: center;
      font-size: 0.875em;
      font-style: italic;
      line-height: 1.6;
      font-weight: 300;
      margin-bottom: 20px;
      @include animated(.475s);
      @include fadeInDown;
      animation-delay: .4s;
    }
    .button {
      margin-top: 10px;
      @include animated(.475s);
      @include fadeInUp;
      animation-delay: .5s;
    }
  }
}