// Site Typography
@mixin h1($margin : 20px) {
  color: $purple;
  font-family: $font-1;
  font-weight: 700;
  font-size: 4.6em;
  line-height: 1.1;
  text-transform: uppercase;
  letter-spacing: -1px;
  margin-bottom: $margin;
}
@mixin h2($margin : 0px) {
  font-size: 1.5em;
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 10px;
  font-family: $font-1;
  margin-bottom: $margin;
}

@mixin p($margin : 20px) {
  font-family: $font-2;
  font-size: 1em;
  line-height: 1.7;
  margin-bottom: $margin;
}


sup {
  vertical-align: super;
}
